import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2L from 'components/typography/h2'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import YouTube from 'components/youtube'
import { FaCaretLeft } from '@react-icons/all-files/fa/FaCaretLeft'
import Layout from 'components/layout'
import Content from 'components/content'
import Module from 'components/modules/module'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const breadcrumbs = [
  {
    name: 'Home',
    schemaName: 'Hutson Inc',
    link: '/',
  },
  {
    name: 'Hutson Customer Portal',
    link: '/hutson-customer-portal/',
  },
  {
    name: 'Ordering Parts',
    link: '/hutson-customer-portal/ordering-parts/',
  },
]

const OrderingParts = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Hutson Customer Portal - Ordering Parts | Hutson Inc</title>
        <meta
          name='description'
          content='Learn how to order parts with the Hutson Hutson Customer Portal.'
        />
        <meta name='keywords' content='Hutson Customer Portal, parts, ordering parts, jdparts' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            'name': 'How to Order Parts Online - Hutson Hutson Customer Portal',
            'description':
              'Learn how to order parts online with the Hutson Customer Portal. Visit our website to login or create an account.',
            'thumbnailUrl': 'https://i.ytimg.com/vi/d02Z0p-NhKo/hqdefault.jpg',
            'uploadDate': '2018-09-21T15:17:54+00:00',
            'duration': 'PT3M6S',
            'embedUrl': 'https://www.youtube.com/embed/d02Z0p-NhKo',
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <HeroImage title='Ordering Parts' image={heroImage} breadcrumbs={breadcrumbs} />

      <Content>
        <YouTube videoId='d02Z0p-NhKo' />

        <Controls>
          <NavControl direction='prev' title='Previous tutorial'>
            <Link to='/hutson-customer-portal/viewing-past-invoices/'>
              <span className='nav-control-label'>Previous:&nbsp;</span>
              <FaCaretLeft aria-hidden='true' focusable='false' role='presentation' />
              <span className='nav-control-title'>How to View Past Invoices</span>
            </Link>
          </NavControl>
          <NavControl direction='next' />
        </Controls>

        <H2L>Video Transcription</H2L>
        <Transcript>
          During the busy season, like planting or harvest season, there's been known to be a line
          at the Hutson parts counter. With the Hutson Hutson Customer Portal, you're now able to
          skip that line. When you order parts through the Hutson Customer Portal, you'll be able to
          walk right into your local Hutson and pick up your part at the counter. No waiting. To
          order the parts, we'll need to click 'Order parts' on the left-hand menu. You'll need to
          select the location that's nearest to you or the one that you do business with. In my
          case, I'm going to select Mayfield. In search terms, you can search by part number or by a
          descriptive word such as closing wheels for a planter. If you don't know your part number
          and you're not really sure what to look up and you're not finding what you need, go over
          to the right and click 'John Deere parts lookup' and you'll find some diagrams on the
          model that you're looking for. In this case, I need some high lift blades for my 915E and
          I know the part number, so I'm going to search by it. This first one matches my part
          number that I searched for and it shows that there's seventeen available and the unit
          price is eleven twenty-seven. Now if my location, Mayfield, didn't have enough blades for
          my needs, I can click these three little bars on the left and it will pull up what the
          other Hutson locations have. Looks like Clarksville has forty-two. So I need three blades
          for my mower, so I'm going to change the quantity to three and then add to cart and then
          I'm going to view my cart. Here you can change your quantity, view your unit price, and
          then you can change your status from stock order to expedite. Then to make ordering this
          part in the future easier, I'll add my equipment to it. I'm gonna select 915E so later on,
          if you want to order blades for your 915E, you can go to my equipment and see what your
          previous orders were. If this is an emergency, you can put machine down, then you can also
          have a quote of what it would be to get that part installed. Right here is your total
          price. We're gonna go ahead and check out and then we're gonna fill out this form so you
          have your delivery method, which is pick up at the parts counter, or ship the parts to my
          location. So I'm gonna pick them up, I'm gonna put the contact name as me, and then any
          order notes that need to be added for the parts counter. Next, you'll need to select the
          day that we want to pick them up. I want to pick mine up tomorrow. Then I'm going to
          complete all the information here on the right and click next. This page gives you a
          complete view of your order to double-check to make sure that everything is right and
          double-check your billing information. Once everything is confirmed, click complete order
          and our parts counter will get to work on your order.
        </Transcript>
      </Content>

      <Module
        text="Save time by adding the Hutson Customer Portal to your phone's home screen"
        buttonText='Learn more'
        link='/blog/hutson-customer-portal-phone-home-screen/'
        image='/uploads/hutson-customer-portal-home-screen.jpg'
      />
    </Layout>
  )
}

const Controls = styled.div`
  margin: 30px 0 40px;

  @media (min-width: 800px) {
    margin-bottom: 60px;
  }
`

const NavControl = styled.div`
  display: block;
  padding-top: 15px;
  text-align: left;
  width: 100%;

  a {
    color: #7d7d7d;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  .nav-control-title,
  .nav-control-label {
    font-size: 1.2em;
    vertical-align: middle;
  }

  .nav-control-title {
    text-decoration: underline;
  }

  svg {
    display: none;
  }

  @media (min-width: 800px) {
    display: inline-block;
    padding-top: 0;
    text-align: ${props => (props.direction === 'next' ? 'right' : 'left')};
    width: 50%;

    .nav-control-label {
      display: none;
    }

    .nav-control-title {
      text-decoration: none;
    }

    svg {
      color: #aaa;
      display: inline-block;
      font-size: 1em;
      margin: 0 10px;
      vertical-align: middle;
    }
  }
`

const Transcript = styled.p`
  margin: 0;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "customer-portal-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default OrderingParts
